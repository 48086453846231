<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ user.hosts[0] ? user.hosts[0].code : 'Universitat' }} intranet
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none" />
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="row mb-3">
              <div class="col-sm-6">
                <p>Benvingut al vostre portal de les convocatòries d'ICREA Acadèmia.</p>
              </div>
            </div>
          </div>
        </div>

        <b-tabs lazy>
          <b-tab active>
            <template #title>
              Premiats/des
            </template>

            <Awarded />
          </b-tab>
          <b-tab>
            <template #title>
              Preseleccionats/des
            </template>

            <Selected />
          </b-tab>
          <b-tab v-if="total > 0">
            <template #title>
              Extensions <span
                v-if="pendings"
                class="badge bg-danger ms-1"
              >{{ pendings }}</span>
            </template>

            <Extensions />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import Selected from './Components/Selected.vue'
import Awarded from './Components/Awarded.vue'
import Extensions from './Components/Extensions.vue'

export default {
  components: {
    BTabs,
    BTab,
    Awarded,
    Extensions,
    Selected,
  },
  computed: {
    ...mapGetters({
      pendings: 'extensionForm/itemsPending',
      user: 'auth/admin',
      total: 'extensionForm/itemsTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('extensionForm/fetch')
    await this.$store.dispatch('convo/fetchAll', 'academy')

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
}
</script>
